<template>
  <v-sheet class="sales" style="height: calc(100vh - 140px)">
    <v-card-text class="px-0">
      <v-row>
        <v-col md="3" class="py-0">
          <label for="date_range" class="custom-form-label" style="font-weight: 600"
            >Filter By Date Range</label
          >
          <DateRangePicker
            hide-details
            @change="searchSales"
            v-on:clear="clearSales()"

            v-model="filterDateRange"
            :disabled="pageLoading"
            :loading="pageLoading"
            id="item-sale-duration"
            placeholder="Date Range"
          ></DateRangePicker>
        </v-col>
        <!-- <v-col md="2" class="py-0">
            <label for="select_month" class="custom-form-label" style="font-weight: 600"
              >Filter by Month</label
            >
            <AutoCompleteInput
              hide-details
              class="mt-3"
              :disabled="pageLoading"
              :loading="pageLoading"
              :items="months"
              id="segment"
              item-text="name"
              item-value="id"
              placeholder="Select Month"
            ></AutoCompleteInput>
          </v-col> -->
        <v-col md="4" class="mt-5">
          <v-row>
            <v-flex>
             
              
              
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="cyan"
                      v-on="on"
                      v-bind="attrs"
                      depressed
                      tile
                      v-on:click="Banking = true"
                      class="ml-2 white--text"
                      >Banking</v-btn
                    >
                  </template>
                  <span>Banking</span>
                </v-tooltip>
              </template>
            </v-flex>
          </v-row>
        </v-col>
        <v-col class="mt-5 justify-content-end">
          <v-flex class="d-flex justify-content-end">
            <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      color="blue darken-4"
                      depressed
                      tile
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      class="white--text"
                    >
                      <v-icon>edit_note</v-icon>
                    </v-btn>
                  </template>
                  <span>Columns arrangement and visibility</span>
                </v-tooltip>

                <v-btn
                  key="cancel-btn"
                  v-show="type == 1"
                  class="ml-2"
                  style="font-weight: 700; color: black"
                  :disabled="pageLoading"
                  depressed
                  tile
                  v-on:click="cancelData"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :disabled="pageLoading || !formValid"
                  v-show="type == 1"
                  :loading="pageLoading"
                  class="white--text mr-2 ml-2"
                  depressed
                  color="blue darken-4"
                  tile
                  v-on:click="saveRecord()"
                >
                  Save
                </v-btn>

                <v-btn
                  key="edit-btn"
                  v-show="type == 0 && sales_detail.length"
                  class="ml-2"
                  color="blue darken-4"
                  style="font-weight: 700; color: white"
                  :disabled="pageLoading"
                  depressed
                  tile
                  v-on:click="editdata"
                >
                  Edit
                </v-btn>
              </template>
              <v-list nav dense>
                <v-list-item
                  draggable
                  @dragstart="dragstart(index)"
                  @dragover="dragover(index)"
                  @dragend="dragEnd"
                  v-for="(item, index) in defaultColDefs"
                  :key="item.field + '_' + index"
                  class="flex-column column-arrangement-list-item cursor-move"
                >
                  <div class="d-flex flex-row">
                    <template v-if="!item.checkbox || Number(item.checkbox) == 0">
                      <v-checkbox
                        :ripple="false"
                        hide-details
                        color="#0d47a1"
                        v-model="defaultColShow"
                        v-bind:value="item.field"
                        :disabled="Number(item.fixed) || pageLoading"
                        v-on:change="updateVisible()"
                        class="m-0"
                      ></v-checkbox>
                      <v-list-item-title>{{ item.headerName }}</v-list-item-title>
                      <v-icon>drag_indicator</v-icon>
                    </template>
                  </div>
                  <v-divider
                    v-if="index < customThead.length - 1"
                    class="mt-1 mb-0"
                    style="width: 100%"
                  ></v-divider>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              class="ml-2"
              style="font-weight: 700; color: black"
              :disabled="pageLoading"
              depressed
              tile
              v-on:click="goBack()"
            >
              Back
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card-text>
    <v-form
      ref="customerForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="saveRecord"
    >
      <v-row>
        <v-simple-table style="width: 100%" fixed-header class="bt-table table_height_summary">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="heading in customThead"
                  :key="heading.title"
                  style="background-color: #f5f5f5"
                >
                  <div v-if="heading.checkbox && Number(heading.checkbox) == 1">
                    <v-checkbox
                      dense
                      v-model="selectedAll"
                      color="#0D47A1"
                      class="hide-margin"
                      hide-details
                    ></v-checkbox>
                  </div>

                  <div v-else>{{ heading.headerName }}</div>
                </th>
              </tr>
            </thead>

            <tbody v-if="!pageLoading" v-click-outside="clickOutside">
              <template v-if="sales_detail.length">
                <tr
                  v-for="(row, rowindex) in sales_detail"
                  :key="rowindex"
                  :class="rowindex % 2 === 0 && 'blue lighten-5'"
                  class="salessummary-listing-tr"
                >
                  <td
                    v-for="(th, index) in customThead"
                    :key="'key_' + index"
                    class="cursor-pointer"
                    :class="`${
                      th.field === 'estimated' ||
                      th.field === 'bank_Fee' ||
                      th.field === 'percentage'
                        ? 'px-0'
                        : 'px-4'
                    }`"
                  >
                    <v-chip
                      small
                      color="#4CAF50"
                      v-if="th.field === 'date' && th.status"
                      style="color: white; font-weight: 600"
                    >
                      {{ formatedate(row.date) }}
                    </v-chip>

                    <!-- <template
                          v-if="th.checkbox && Number(th.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            :disabled="dataLoading"
                            v-model="selectedAll"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template> -->
                    <div v-else-if="th.field === 'id' && th.status">
                      <v-checkbox
                        v-model="selectedRows"
                        class="mu-auto py-0"
                        color="#0D47A1"
                        :disabled="row.banking != 0"
                        v-bind:value="row"
                        v-on:click="() => getPayments(selectedRows)"
                      ></v-checkbox>
                    </div>
                    <div v-else-if="th.field === 'action' && th.status">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            @click="routeToUpdate(row.id)"
                            class="mx-2"
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            class="me-2"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            v-on:click.stop.prevent="deleteConfirm(row)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                      <!-- <template v-if="row.banking != null">No Action</template> -->
                    </div>
                    <div v-else-if="th.field === 'master' && th.status">
                      <template>
                        {{ formatMoney(row.sales) }}
                      </template>
                    </div>
                    <div v-else-if="th.field === 'total_bank_Fee' && th.status">
                      <template>
                        {{ formatMoney(row.bank_fee_1 + row.bank_fee_2) }}
                      </template>
                    </div>
                    <div v-else-if="th.field === 'total_estimated' && th.status">
                      <template>
                        {{ formatMoney(row.estimated_fee_1 + row.estimated_fee_2) }}
                      </template>
                    </div>
                    <div v-else-if="th.field === 'percentage' && th.status">
                      <template v-if="type == 1">
                        <div class="text-center mx-2" span="2">
                          <QuantityInput
                            @keyup="checkPercentage_1(row, rowindex)"
                            :id="'percentage_1' + rowindex"
                            :ref="'percentage_1' + rowindex"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-model="row.percentage_1"
                            customClass="px-0 mx-2 "
                          ></QuantityInput>
                        </div>
                      </template>
                      <template v-else>
                        <div class="text-center mx-2" span="2">
                          <template>{{
                            row.percentage_1 ? row.percentage_1.toFixed(2) : 0.0
                          }}</template>
                        </div>
                      </template>
                      <v-divider class="mt-2 mb-0"></v-divider>
                      <template v-if="type == 1">
                        <div class="text-center mx-2" span="2">
                          <QuantityInput
                            @keyup="checkPercentage_2(row, rowindex)"
                            :id="'percentage_2' + rowindex"
                            :ref="'percentage_2' + rowindex"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-model="row.percentage_2"
                            customClass="px-0 mx-2 "
                          ></QuantityInput>
                        </div>
                      </template>
                      <template v-else>
                        <div class="text-center mx-2" span="2">
                          <template>{{
                            row.percentage_2 ? row.percentage_2.toFixed(2) : 0.0
                          }}</template>
                        </div>
                      </template>
                    </div>
                    <div v-else-if="th.field === 'bank_Fee' && th.status">
                      <div class="text-center mx-2" span="2">
                        {{ formatMoney(row.bank_fee_1) }}
                      </div>
                      <hr class="mt-2 mb-0" />
                      <div class="text-center mx-2" span="2">
                        {{ formatMoney(row.bank_fee_2) }}
                      </div>
                    </div>
                    <div v-else-if="th.field === 'estimated' && th.status">
                      <div class="text-center mx-2" span="2">
                        <template v-if="type == 1">
                          <div class="text-center mx-2" span="2">
                            <QuantityInput
                              :id="'estimated_fee_1' + rowindex"
                              :ref="'estimated_fee_1' + rowindex"
                              hide-details
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              v-model="row.estimated_fee_1"
                              customClass="px-0 mx-2 "
                            ></QuantityInput>
                          </div>
                        </template>
                        <template v-else>
                          <div class="text-center mx-2" span="2">
                            <template>{{
                              row.estimated_fee_1 ? row.estimated_fee_1.toFixed(2) : 0.0
                            }}</template>
                          </div>
                        </template>
                      </div>
                      <hr class="mt-2 mb-0" />
                      <div class="text-center mx-2" span="2">
                        <template v-if="type == 1">
                          <div class="text-center mx-2" span="2">
                            <QuantityInput
                              :id="'estimated_fee_2' + rowindex"
                              :ref="'estimated_fee_2' + rowindex"
                              hide-details
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              v-model="row.estimated_fee_2"
                              customClass="px-0 mx-2 "
                            ></QuantityInput>
                          </div>
                        </template>
                        <template v-else>
                          <div class="text-center mx-2" span="2">
                            <template>{{
                              row.estimated_fee_2 ? row.estimated_fee_2.toFixed(2) : 0.0
                            }}</template>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div v-else-if="th.field === 'total_amount' && th.status">
                      <template v-if="type == 1">
                        <div class="text-center mx-2" span="2">
                          <QuantityInput
                            :id="'amount_1' + rowindex"
                            :ref="'amount_1' + rowindex"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-model="row.amount_1"
                            @keyup="checkAmount_1(row, rowindex)"
                            customClass="px-0 mx-2 "
                          ></QuantityInput>
                        </div>
                      </template>

                      <template v-else>
                        <div class="text-center mx-2" span="2">
                          <template>{{ formatMoney(row.amount_1) }}</template>
                        </div>
                      </template>

                      <v-divider class="mt-2 mb-0"></v-divider>
                      <template v-if="type == 1">
                        <div class="text-center mx-2" span="2">
                          <QuantityInput
                            :id="'amount_2' + rowindex"
                            :ref="'amount_2' + rowindex"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-model="row.amount_2"
                            @keyup="checkAmount_2(row, rowindex)"
                            customClass="px-0 mx-2 "
                          ></QuantityInput>
                        </div>
                      </template>

                      <template v-else>
                        <div class="text-center mx-2" span="2">
                          <template>{{ formatMoney(row.amount_2) }}</template>
                        </div>
                      </template>
                    </div>
                    <div v-else-if="th.field === 'received' && th.status">
                      <div>
                        <template>
                          <v-chip color="red" style="color: white; font-weight: 600">
                            {{ formatMoney(row.banking) }}</v-chip
                          >
                        </template>
                      </div>
                      <div class="mt-2">
                        <v-chip
                          v-if="row.barcode"
                          color="#0D47A1"
                          style="color: white; font-weight: 600"
                          @click="openBank(row.banking)"
                        >
                          {{ row.barcode }}</v-chip
                        >
                      </div>
                    </div>
                    <div v-else-if="th.field === 'discrepancy' && th.status">
                      <template>
                        {{ formatMoney(row.banking - row.total_sales) }}
                      </template>
                    </div>

                    <div v-else-if="th.field === 'banking' && th.status">
                      <template v-if="row.banking">
                        <div>
                          <template v-if="row.bank_total != 0">
                            <v-chip color="red" style="color: white; font-weight: 600">
                              {{ formatMoney(row.bank_total) }}</v-chip
                            >
                          </template>
                        </div>
                        <div class="mt-2">
                          <v-chip
                            color="#0D47A1"
                            style="color: white; font-weight: 600"
                            @click="openBank(row.banking)"
                          >
                            {{ row.banking }}</v-chip
                          >
                        </div>
                      </template>
                      <template v-else> - </template>
                    </div>
                    <div v-else-if="th.field === 'voucher_claim' && th.status">
                      <template>
                        {{ formatMoney(row.voucher_claim) }}
                      </template>
                    </div>
                    <div v-else-if="th.field === 'voucher_sold' && th.status">
                      <template>
                        {{ formatMoney(row.voucher_sold) }}
                      </template>
                    </div>
                    <div v-else-if="th.field === 'voucher_award' && th.status">
                      <template>
                        {{ formatMoney(row.voucher_award) }}
                      </template>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="8">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no Mastercard Sales at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 8" :key="idr">
                <td v-for="idk in 8" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-row>
    </v-form>

    <v-layout hide-details v-if="sales_detail.length" class="light-border-top mt-5">
      <v-flex md6>
        <label class="btx-label p-4"
          >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
        >
      </v-flex>
      <v-flex md6>
        <v-pagination
          color="blue darken-4"
          v-model="currentPage"
          :length="pageLimit"
          :total-visible="7"
        ></v-pagination>
      </v-flex>
    </v-layout>

    <OrderTemplate
      v-model="order"
      :customer-id="sales_detailId"
      :dialog="orderDialog"
      v-on:close="orderDialog = false"
      v-on:save="saveOrder()"
      v-on:collect:voucher="getAvailableVouchers"
      v-on:claim:voucher="getCollectedVouchers"
    >
    </OrderTemplate>
    <CollectTemplate
      :dialog="collectDialog"
      :order-id="cOrderId"
      :order-code="cOrderCode"
      :vouchers="cVouchers"
      v-on:close="vCollected()"
      v-on:collected="vCollected()"
    ></CollectTemplate>
    <RedeemTemplate
      :dialog="redeemDialog"
      :order-id="rOrderId"
      :order-code="rOrderCode"
      :vouchers="rVouchers"
      v-on:close="vRedeemed()"
      v-on:redeemed="vRedeemed()"
    ></RedeemTemplate>
    <DeleteTemplate
      type="sales summary"
      delete-note="All leave of this sales will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getSalesSummary()"
    >
    </DeleteTemplate>
    <SearchTemplate
      :dialog="allCollectedDialog"
      v-on:close="allCollectedDialog = false"
    ></SearchTemplate>
    <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
      <template v-slot:title> Whatsapp Promotional </template>
      <template v-slot:body>
        <div class="mx-6" fluid>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
            </v-col>
            <v-col md="8">
              <SelectInput
                hide-details
                hideTopMargin
                :loading="pageLoading"
                :disabled="pageLoading"
                item-text="text"
                dense
                item-value="value"
                :items.sync="templateList"
                v-model="whatsapp.template"
              ></SelectInput>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-col>
            <v-col md="8">
              <v-file-input
                v-model="whatsapp.files"
                ref="fileInput"
                hide-details
                id="item-file-upload"
                label="Upload Images"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          @click="whatsappPromotional = false"
        >
          Send
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <Dialog :dialog="openDialog" card-text-class="px-4 py-0" :dialogWidth="800">
      <template v-slot:title>Banking<v-spacer></v-spacer></template>
      <template v-slot:body>
        <v-container>
          <v-row>
            <v-col md="12" class="py-1">
              <!-- <div class="custom-header-blue-text text-h5 text-center mb-2">Record Payment</div> -->
              <table class="table table-sm mb-0" width="100%">
                <thead class="table-light">
                  <tr>
                    <th>#</th>
                    <th class="text-center">Date</th>

                    <th class="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in item"
                    :key="index"
                    :class="index % 2 === 0 && 'blue lighten-5'"
                    class="salessummary-listing-tr"
                  >
                    <td class="text-h6">
                      {{ index + 1 }}
                    </td>
                    <td align="center" class="text-capitalize text-h6">
                      {{ formatedate(row.date) }}
                    </td>
                    <td align="end" class="text-h6">{{ formatMoney(row.cash) }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
          <template> </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn class="ml-2" depressed tile v-on:click="close"> Cancel </v-btn>
      </template>
    </Dialog>
    <Banking
      :dialog="Banking"
      :detail="data"
      :selectedRows="selectedRows"
      @close="handleClose"
    ></Banking>
  </v-sheet>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import {
  GET_MASTER_LIST_SALE,
  GET_TEMPLATE_LIST,
  CREATE_MASTER_SALE_UPDATE,
} from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import RedeemTemplate from "@/view/components/RedeemVoucherTemplate";
import SearchTemplate from "@/view/components/SearchAllVouchers";
import DateRangePicker from "@/view/components/DateRangePicker";
import QuantityInput from "@/view/components/QuantityInput";
import PosMixin from "@/core/mixins/pos.mixin";
import Banking from "./Banking";
//  import TextInput from "@/view/components/TextInput";
import { SET_MESSAGE } from "@/core/services/store/common.module";

import { SearchEventBus } from "@/core/lib/search.lib";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";

import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import SelectInput from "@/view/components/SelectInput.vue";
import moment from "moment-timezone";
import { filter, map } from "lodash";
import { POST, QUERY } from "@/core/services/store/request.module";
//import AutoCompleteInput from "@/view/components/AutoCompleteInput";

export default {
  mixins: [PosMixin],

  name: "sales-update",
  title: "Details Sales Summry",
  data() {
    return {
      text: "Also called a company description, your business description summarizes what your company does, its purpose, and what makes it unique. It's a critical component of your business plan and immediately follows your executive summary",
      dialog: false,
      data: [],
      activeRowIndex: null,
      defaultColDefs: [],
      selectedRows: [],
      formValid: true,
      estimated_fee: 0,
      type: 0,
      banking: 0,
      customThead: [],
      Qr_sales: 0,
      fee: 0,
      detail: [],
      Banking: false,
      openDialog: false,
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      discrepancy: 0,
      whatsapp: {
        files: [],
        template: "first",
      },
      summaryTableHeading: [
        { title: "CHECK", status: true },
        { title: "ACTION", status: true },
        { title: "DATE", status: true },
        { title: "TOTAL SALES", status: true },
        { title: "CASH SALES", status: true },
        { title: "VISA", status: true },
        { title: "DEBIT", status: true },
        { title: "MASTERCARD", status: true },
        { title: "ONLINE BANKING", status: true },
        { title: "TOUCH AND GO", status: true },
        { title: "QR PAY", status: true },
        { title: "GRAB PAY", status: true },
        { title: "BOOST", status: true },
        { title: "GRAB FOOD", status: true },
        { title: "FOOD PANDA", status: true },
        { title: "FOC", status: true },
        { title: "VOUCHER CLAIM", status: true },
        { title: "VOUCHER SOLD", status: true },
        { title: "VOUCHER AWARD", status: true },
        { title: "BANKING", status: true },
      ],
      months: [
        {
          name: "January",
          value: 1,
        },
        {
          name: "February",
          value: 2,
        },
        {
          name: "March",
          value: 3,
        },
        {
          name: "April",
          value: 4,
        },
        {
          name: "May",
          value: 5,
        },
        {
          name: "June",
          value: 6,
        },
        {
          name: "July",
          value: 7,
        },
        {
          name: "August",
          value: 8,
        },
        {
          name: "September",
          value: 9,
        },
        {
          name: "October",
          value: 10,
        },
        {
          name: "November",
          value: 11,
        },
        {
          name: "December",
          value: 12,
        },
      ],
      detailSummaryData: [],
      filterType: "all",
      filterDateRange: null,
      speedDial: [],

      whatsappPromotional: false,
      sales_detail: [],
      item: [],
      visa_card: 0,
      debit_card: 0,
      totalsales: 0,
      currentPage: 1,
      online_banking: 0,
      pageLimit: 1,
      showColumn: false,
      showingFrom: 0,
      showingTo: 0,
      master_card: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      sales_detailId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      dragStartIndex: null,
      dragOverIndex: null,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    selectedRows() {
      this.rowSelected();
    },
    summaryTableHeading: {
      handler: function (newval) {
        console.log(newval);
      },
      deep: true,
    },
    currentPage() {
      this.getSalesSummary();
    },
    listingSearch() {
      this.getSalesSummary();
    },
  },
  methods: {
    handleClose() {
      // this.data = null;

      this.Banking = false;
    },
    async saveRecord() {
      if (!this.$refs.customerForm.validate()) {
        return false;
      }
      // this.sales_date = this.sales_detail
      // this.sales_id = this.sales.id
      // this.arrays = this.sales_detail.filter(array => array.amount > 0);
      try {
        const customer = await CREATE_MASTER_SALE_UPDATE(this.sales_detail);
        console.log(customer);

        this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Master Card Updated Successfully." },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.getSalesSummary();
        this.type = 0;
      }
    },
    editdata() {
      this.type = 1;
    },
    cancelData() {
      this.type = 0;
    },

    checkPercentage_1(row, index) {
      if (this.sales_detail[index].percentage_1 > 100) {
        this.sales_detail[index].percentage_1 = 0;
        this.sales_detail[index].bank_fee_1 = 0;
      }
      if (this.sales_detail[index].percentage_1 <= 0) {
        this.sales_detail[index].percentage_1 = 0;
        this.sales_detail[index].bank_fee_1 = 0;
        this.sales_detail[index].estimated_fee_1 = this.sales_detail[index].amount_1;
      }

      if (this.sales_detail[index].percentage_1) {
        this.sales_detail[index].bank_fee_1 =
          (this.sales_detail[index].amount_1 * this.sales_detail[index].percentage_1) / 100;
        this.sales_detail[index].estimated_fee_1 =
          this.sales_detail[index].amount_1 -
          (this.sales_detail[index].amount_1 * this.sales_detail[index].percentage_1) / 100;
        // if(this.sales_detail[index].amount_1 <=0){
        //     this.sales_detail[index].estimated_fee_1 = 0;
        //     this.sales_detail[index].bank_fee_2 = 0;

        // }else{
        //     this.sales_detail[index].bank_fee_2 = this.sales_detail[index].amount_2*this.sales_detail[index].percentage_2/100;
        //     this.sales_detail[index].estimated_fee_2 = this.sales_detail[index].amount_2-this.sales_detail[index].amount_2*this.sales_detail[index].percentage_2/100;
        // }
      }
    },
    checkPercentage_2(row, index) {
      if (this.sales_detail[index].percentage_2 > 100) {
        this.sales_detail[index].percentage_2 = 0;
        this.sales_detail[index].bank_fee_2 = 0;
      }
      if (this.sales_detail[index].percentage_2 <= 0) {
        this.sales_detail[index].percentage_2 = 0;
        this.sales_detail[index].bank_fee_2 = 0;
        this.sales_detail[index].estimated_fee_2 = this.sales_detail[index].amount_2;
      }

      if (this.sales_detail[index].percentage_2) {
        this.sales_detail[index].bank_fee_2 =
          (this.sales_detail[index].amount_2 * this.sales_detail[index].percentage_2) / 100;
        this.sales_detail[index].estimated_fee_2 =
          this.sales_detail[index].amount_2 -
          (this.sales_detail[index].amount_2 * this.sales_detail[index].percentage_2) / 100;
        // if(this.sales_detail[index].amount_1 <=0){
        //     this.sales_detail[index].estimated_fee_1 = 0;
        //     this.sales_detail[index].bank_fee_2 = 0;

        // }else{
        //     this.sales_detail[index].bank_fee_2 = this.sales_detail[index].amount_2*this.sales_detail[index].percentage_2/100;
        //     this.sales_detail[index].estimated_fee_2 = this.sales_detail[index].amount_2-this.sales_detail[index].amount_2*this.sales_detail[index].percentage_2/100;
        // }
      }
    },

    checkAmount_1(row, index) {
      if (this.sales_detail[index].amount_1 > this.sales_detail[index].sales) {
        this.sales_detail[index].amount_2 = 0;
        this.sales_detail[index].amount_1 = 0;
        this.sales_detail[index].estimated_fee_1 = 0;
        this.sales_detail[index].bank_fee_1 = 0;
      }
      if (this.sales_detail[index].amount_1 <= 0) {
        this.sales_detail[index].amount_2 = this.sales_detail[index].sales;
        this.sales_detail[index].amount_1 = 0;
        this.sales_detail[index].estimated_fee_1 = 0;
        this.sales_detail[index].bank_fee_1 = 0;
        this.sales_detail[index].bank_fee_2 =
          (this.sales_detail[index].amount_2 * this.sales_detail[index].percentage_2) / 100;
        this.sales_detail[index].estimated_fee_2 =
          this.sales_detail[index].amount_2 - this.sales_detail[index].bank_fee_2;
      }

      if (this.sales_detail[index].amount_1) {
        this.sales_detail[index].amount_2 =
          this.sales_detail[index].sales - this.sales_detail[index].amount_1;
        this.sales_detail[index].bank_fee_1 =
          (this.sales_detail[index].amount_1 * this.sales_detail[index].percentage_1) / 100;
        this.sales_detail[index].estimated_fee_1 =
          this.sales_detail[index].amount_1 - this.sales_detail[index].bank_fee_1;
        if (this.sales_detail[index].amount_2 <= 0) {
          this.sales_detail[index].estimated_fee_2 = 0;
          this.sales_detail[index].bank_fee_2 = 0;
        } else {
          this.sales_detail[index].bank_fee_2 =
            (this.sales_detail[index].amount_2 * this.sales_detail[index].percentage_2) / 100;
          this.sales_detail[index].estimated_fee_2 =
            this.sales_detail[index].amount_2 - this.sales_detail[index].bank_fee_2;
        }
      }
    },

    checkAmount_2(row, index) {
      if (this.sales_detail[index].amount_2 > this.sales_detail[index].sales) {
        this.sales_detail[index].amount_2 = 0;
        this.sales_detail[index].amount_1 = 0;
        this.sales_detail[index].estimated_fee_2 = 0;
      }
      if (this.sales_detail[index].amount_2 <= 0) {
        this.sales_detail[index].amount_1 = this.sales_detail[index].sales;
        this.sales_detail[index].amount_2 = 0;
        this.sales_detail[index].estimated_fee_2 = 0;
        this.sales_detail[index].bank_fee_1 =
          (this.sales_detail[index].amount_1 * this.sales_detail[index].percentage_1) / 100;

        this.sales_detail[index].estimated_fee_1 =
          this.sales_detail[index].amount_1 - this.sales_detail[index].bank_fee_1;
      }

      if (this.sales_detail[index].amount_2) {
        this.sales_detail[index].amount_1 =
          this.sales_detail[index].sales - this.sales_detail[index].amount_2;
        this.sales_detail[index].bank_fee_2 =
          (this.sales_detail[index].amount_2 * this.sales_detail[index].percentage_2) / 100;

        this.sales_detail[index].estimated_fee_2 =
          this.sales_detail[index].amount_2 - this.sales_detail[index].bank_fee_2;

        if (this.sales_detail[index].amount_1 <= 0) {
          this.sales_detail[index].estimated_fee_1 = 0;
          this.sales_detail[index].bank_fee_1 = 0;
        } else {
          this.sales_detail[index].bank_fee_1 =
            (this.sales_detail[index].amount_1 * this.sales_detail[index].percentage_1) / 100;

          this.sales_detail[index].estimated_fee_1 =
            this.sales_detail[index].amount_1 - this.sales_detail[index].bank_fee_1;
        }
      }

      console.log(row, "row");
      console.log(index, "index");
    },
    clickOutside() {
      this.activeRowIndex = null;
    },
    // onFocus(event,Index) {
    //     this.activeRowIndex = Index;

    //     this.$nextTick(() => {
    //         const ourForm = event.target;
    //         const ourInp = ourForm.querySelector("input");
    //         if (ourInp) ourInp.focus() || ourInp.select();
    //     });

    // },
    // showColumn(){
    //   this.showColumn = true;
    // },
    reset() {
      this.filterDateRange = null;
      this.getSalesSummary();
    },
    clearSales() {
     this.filterDateRange=null;
     this.getSalesSummary();

   
 },
    searchSales() {
      if(this.filterDateRange){
        this.getSalesSummary();

      }
    },
    close() {
      this.openDialog = false;
    },
    openBank(bank) {
      this.openDialog = true;
      console.log(bank, "bank");
      this.$store
        .dispatch(QUERY, {
          url: `find-banking/${bank}`,
        })
        .then((data) => {
          this.item = data.rows;
          console.log(data);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {});
    },
    rowSelected() {
      this.totalSelected = this.selectedRows.length;
    },
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }
      // console.log(this.defaultColDefs);

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.getSalesSummary();
          });
      });
    },
    getPayments(selectedRows) {
      this.data = selectedRows;
      console.log(selectedRows, "selectedAll");
    },
    formatedate(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.defaultColDefs[this.dragStartIndex];
      this.defaultColDefs.splice(this.dragStartIndex, 1);
      this.defaultColDefs.splice(this.dragOverIndex, 0, dragStartElement);
      console.log(this.salestableHeading, "dragStartElement");

      this.$nextTick(() => {
        this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.getSummary();
          });
      });
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    downloadTableDataOfSummary() {
      const summaryContent = this.generateCSV();
      const blobContent = new Blob([summaryContent], { type: "text/csv" });
      const downLoadlink = document.createElement("a");
      downLoadlink.href = window.URL.createObjectURL(blobContent);
      downLoadlink.download = "restaurant.csv";
      downLoadlink.click();
    },
    generateCSV() {
      const tableHeaders = [
        "DATE",
        "GRAB PAY",
        "CASH SALES",
        "GRAB FOOD",
        "TOUCH AND GO",
        "FOOD PANDA",
        "DEBIT",
        "BOOST",
        "ONLINE BANK IN",
        "MAY BANK",
        "FOC",
        "SHOPPEPAY",
        "TOTAL SALES",
        "VOUCHER",
        "CITY LEDGER",
      ];
      const rows = this.detailSummaryData;
      console.log(rows.date.value, "rows");
      // (
      //   rows.date.value,
      //   rows.grab_pay.value,
      //   rows.cash_sales.value
      // );
      // .map((item) => [
      //   item.date.value,
      //   item.grab_pay.value,
      //   item.cash_sales.value,
      //   item.grab_food.value,
      //   item.touch_and_go.value,
      //   item.food_panda.value,
      //   item.debit.value,
      //   item.boost.value,
      //   item.online_bank_in.value,
      //   item.may_bank.value,
      //   item.foc.value,
      //   item.shoppepay.value,
      //   item.total_sales.value,
      //   item.voucher.value,
      //   item.city_ledger.value,
      // ]);
      //...rows.map((row) => row.join(","))
      const csvRows = [tableHeaders.join(","), rows];
      return csvRows.join("\n");
    },

    filterTypeChange() {
      this.filterDateRange = null;
    },
    saveOrder() {
      this.sales_detailId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.sales_detailId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.sales_detailId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "sales-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "sales-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ barcode, id }) {
      this.deleteText = barcode;
      this.deleteEndpoint = `delete-sale/${id}`;
      this.deleteDialog = true;
    },
    async getSalesSummary() {
      try {
        let form = {
          current_page: this.currentPage,
          search: this.listingSearch,
          filter_type: this.filterType,
          month: this.$route.params.id,
          year: this.$route.query.year,
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const {
          rows,
          totalPages,
          showingFrom,
          showingTo,
          totalRows,
          theads,
          fee,
          Qr_sales,
          estimated_fee,
          banking,
          discrepancy,
          online_banking,
        } = await GET_MASTER_LIST_SALE(form);
        this.sales_detail = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
        this.fee = fee;
        this.Qr_sales = Qr_sales;
        this.estimated_fee = estimated_fee;
        this.banking = banking;
        this.discrepancy = discrepancy;
        this.online_banking = online_banking;
        this.$emit("saveAddress", Qr_sales);
        this.$emit("fee", fee);
        this.$emit("estimated_fee", estimated_fee);
        this.$emit("banking", banking);
        this.$emit("discrepancy", discrepancy);

        let thead = theads;
        this.defaultColDefs = thead;
        this.customThead = [];
        for (let index = 0; index < thead.length; index++) {
          let element = thead[index];
          if (element.visible == 1) {
            this.customThead.push(element);
          }
        }

        let t_out = filter(thead, (row) => Number(row.visible) == 1);
        this.defaultColShow = map(t_out, "field");

        console.log(this.customThead);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {});
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    this.getSalesSummary();
    const { name, params } = this.$route;
    if (name === "mastercard-detail") {
      this.detailSummaryData = params.row;
    } else {
      this.goBack();
    }
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "MASTER SALES");
  },

  components: {
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    RedeemTemplate,
    SearchTemplate,
    DateRangePicker,
    // TextInput,
    SelectInput,
    Dialog,
    QuantityInput,
    //AutoCompleteInput,
    // ValueTemplate,
    Banking,
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
    SearchEventBus.$emit("search-template", false);
  },
  computed: {
    ...mapGetters(["listingSearch"]),
    selectedAll: {
      set(param) {
        this.selectedRows = [];
        if (param && this.sales_detail.length) {
          for (let i = this.sales_detail.length - 1; i >= 0; i--) {
            this.selectedRows.push(this.sales_detail[i]);
            this.data = this.selectedRows;
            console.log(this.data, "this.data");
          }
        }
      },
      get() {
        let totalItems = this.sales_detail.length;

        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      },
    },
  },
};
</script>

<style>
.table_height_summary .v-data-table__wrapper {
  height: calc(100vh - 340px);
}

::-webkit-scrollbar {
  height: 10px;
}

.detail-salessummary-listing-tr:nth-child(even) {
  background-color: #e3f2fd !important;
  border-color: #e3f2fd !important;
}

.detail-salessummary-listing-tr:nth-child(odd) {
  background-color: #e3f2fd !important;
  border-color: #e3f2fd !important;
}

.detail-salessummary-listing-tr:nth-child(even):hover {
  background: #fff !important;
  box-shadow: 0px 0px 20px 2px #666666 !important;
  -webkit-box-shadow: 0px 0px 20px 2px #666666 !important;
  -moz-box-shadow: 0px 0px 20px 2px #666666 !important;
}

.detail-salessummary-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 20px 2px #666666 !important;
  -webkit-box-shadow: 0px 0px 20px 2px #666666 !important;
  -moz-box-shadow: 0px 0px 20px 2px #666666 !important;
}

.table.table-sm td {
  padding: 0.6rem !important;
  vertical-align: middle;
}

.table.table-sm th {
  background-color: #ede8e8 !important;
}
</style>
